import Layout from "../../components/layout/Layout"
import React from "react"

export default function Articles() {
  return (
    <Layout>
      <div>
        <h2>Articles</h2>
      </div>
    </Layout>
  )
}
